import _ from "lodash";
import "./LineSelect.scss";
import LineTab from "./tab";
import { globalVal } from "../../global/globalvalues";
import { useEffect, useRef, useState } from "react";
import planData from "../../data.json";
import PlanCard from "../../components/HomeComponents/PlanCard";
import { useNavigate } from "react-router-dom";
import wowDiscountCoupons from "../../wowDiscountCoupons.json";
import WowDiscount from "./Modals/WowDiscount";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";
import { useDispatch, useSelector } from "react-redux";
import { HomeService } from "../../services/home.service";
import WowDiscountSuccess from "./Modals/WowDiscountSuccess";
import WowDiscountUnsuccess from "./Modals/WowDiscountUnsuccess";
import WowDiscountUnsuccessMatchFound from "./Modals/WowDiscountUnsuccessMatchFound";
import { NotificationComponent } from "../NotificationComponent/NotificationComponent";
import {
  discountAppliedFailure,
  discountAppliedSuccess,
} from "../../redux/HomePage/actions";
import NutritionCard from "./NutritionLable";
import Switch from "@mui/material/Switch";
import { BillingService } from "../../services/billing.service";

type initialProps = {
  fiveGPlans?: any;
  history?: any;
  isAllowed: boolean;
};

const LineSelect: React.FC<initialProps> = (props) => {
  const navigate = useNavigate();
  const [shareablePlans, setSharablePlans] = useState<any>();
  const [unlimitedPlans, setUnlimitedPlans] = useState<any>();
  const [selectedLine, setSelectedLine] = useState<number>(1);
  const [planUnlimitedCost, setPlanUnlimitedCost] = useState<number>();
  const [planUnlimitedPerLinesCost, setPlanUnlimitedPerLinesCost] =
    useState<number>();
  const [planCost, setPlanCost] = useState<number>();
  const [planPerLinesCost, setPlanPerLinesCost] = useState<number>();
  const [activePlan, setActivePlan] = useState<number>(3);
  const shareablePlan = _.filter(props.fiveGPlans, { isUnlimited: false });
  const unlimitedPlan = _.filter(props.fiveGPlans, { isUnlimited: true });
  const [tabVal, setTabVal] = useState<number>(1);
  const [selectedPlan, setSelectedPlan] = useState<any>(null);
  let [multiplier, setMultiplier] = useState<any>(1);
  const [wowUnlimitedPlanResponse, setWowUnlimitedPlanResponse] =
    useState<any>(null);
  const [wowNormalPlanResponse, setWowNormalPlanResponse] = useState<any>(null);
  const [wowEligibilityCheckCounter, setWowEligibilityCheckCounter] =
    useState(0);
  const [perLineCost, setPerLineCost] = useState<any>();
  const [wowDiscountUnsuccessModal, setWowDiscountUnsuccessModal] =
    useState(false);
  const [wowDiscountModal, setWowDiscountModal] = useState(false);
  const initialWowCustomerData = {
    wowAccountNumber: "",
    wowAccountServiceZipcode: "",
    wowCustomerLastName: "",
    // wowCustomerEmail: "",
  };
  const [wowCustomerData, setWowCustomerData] = useState(
    initialWowCustomerData
  );
  const [formDataValidation, setformDataValidation] = useState(false);
  const [isCheckEligibilityClicked, setIsCheckEligibilityClicked] =
    useState(false);
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const popoverId = open ? "simple-popover" : undefined;
  const [wowDataObject, setWowDataObject] = useState<any>(null);
  const [wowDiscountSuccessModal, setWowDiscountSuccessModal] = useState(false);
  const {
    wowEligibility,
    wowEligibilityError,
    discountApplied,
    discountAppliedError,
  } = useSelector((state: any) => state.home);
  const [
    wowDiscountUnsuccessModalWithMatchFound,
    setWowDiscountUnsuccessModalWithMatchFound,
  ] = useState(false);
  let currentuser: any = localStorage.getItem("currentuser");
  currentuser = JSON.parse(currentuser);

  useEffect(() => {
    const selectedPlanData = props.fiveGPlans.data[0];
    setSharableCost(selectedPlanData, 0);
    const unlimitedPlan = _.filter(props.fiveGPlans.data, {
      isUnlimited: true,
    });
    setWowUnlimitedPlanResponse(wowDiscountCoupons?.unlimited);
    setWowNormalPlanResponse(wowDiscountCoupons?.regular);
    setUnlimitedCost(unlimitedPlan);
    setSharablePlans(selectedPlanData);
    setUnlimitedPlans(unlimitedPlan);
  }, []);
  const initialRender1 = useRef(true);
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (wowEligibility && wowEligibility.status === 200) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        setWowDiscountSuccessModal(true);
      }
      if (wowEligibility && wowEligibility.status === 206) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        setWowDiscountUnsuccessModalWithMatchFound(true);
      }
    }
  }, [wowEligibility]);

  useEffect(() => {
    setWowNormalPlanResponse(wowDiscountCoupons?.regular);
    setWowUnlimitedPlanResponse(wowDiscountCoupons?.unlimited);
    let currentuser: any = localStorage.getItem("currentuser");
    currentuser = JSON.parse(currentuser);
    if (!currentuser?.isAnonymous) {
      // let lastName = currentuser?.displayName?.split(" ")[1];
      let splittedNames = currentuser?.displayName?.split(" ");
      let lastName = splittedNames?.slice(1)?.join(" ");

      setWowCustomerData((prevProps) => ({
        ...prevProps,
        wowCustomerLastName: lastName,
        // wowCustomerEmail: currentuser?.email,
      }));
    } else {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  }, []);

  const initialRender2 = useRef(true);
  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (
        (wowEligibilityError && wowEligibilityError.status === 404) ||
        (wowEligibilityError && wowEligibilityError.status === 504)
      ) {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        // setIsWowExistingCustomer(false);
        setWowDiscountUnsuccessModal(true);
        setWowEligibilityCheckCounter(wowEligibilityCheckCounter + 1);
      } else {
        setIsCheckEligibilityClicked(false);
        setWowDiscountModal(false);
        NotificationComponent({
          message: "Something went wrong, please try again",
          type: "danger",
          duration: 2000,
          id: "EligibilityError",
        });
      }
    }
  }, [wowEligibilityError]);
  const initialRender3 = useRef(true);
  useEffect(() => {
    if (initialRender3.current) {
      initialRender3.current = false;
    } else {
      if ((discountApplied && discountApplied.status) === "SUCCESS") {
        localStorage.setItem("isWowDiscountEligible", "true");
        localStorage.setItem(
          "wowUserDetails",
          JSON.stringify({
            userInfo: wowCustomerData,
            isUnlimitedPlan: selectedPlan?.isUnlimited,
          })
        );
        localStorage.setItem(
          "reachSelectedPlanLine",
          JSON.stringify(selectedLine)
        );

        localStorage.setItem(
          "reachSelectedPlanDetail",
          JSON.stringify({
            line: selectedLine,
            plan: selectedPlan,
          })
        );

        setIsCheckEligibilityClicked(false);
        setWowDiscountSuccessModal(false);
        setWowDiscountUnsuccessModalWithMatchFound(false);
        dispatch(discountAppliedSuccess({ discountApplied: null }));
        navigate("/checkout", {
          state: { ...wowDataObject, discountApplied: true },
        });
      }
    }
  }, [discountApplied]);
  const initialRender4 = useRef(true);

  useEffect(() => {
    if (initialRender4.current) {
      initialRender4.current = false;
    } else {
      if (
        (discountAppliedError && discountAppliedError.status) === 404 ||
        (discountAppliedError &&
          discountAppliedError.data &&
          discountAppliedError.data.status) === "FAILURE"
      ) {
        localStorage.setItem("isWowDiscountEligible", "false");
        localStorage.setItem(
          "wowUserDetails",
          JSON.stringify({
            userInfo: wowCustomerData,
            isUnlimitedPlan: selectedPlan?.isUnlimited,
          })
        );
        localStorage.setItem(
          "reachSelectedPlanLine",
          JSON.stringify(selectedLine)
        );
        localStorage.setItem(
          "reachSelectedPlanDetail",
          JSON.stringify({
            line: selectedLine,
            plan: selectedPlan,
          })
        );
        setIsCheckEligibilityClicked(false);
        setWowDiscountSuccessModal(false);
        setWowDiscountUnsuccessModalWithMatchFound(false);
        NotificationComponent({
          message:
            "You have already applied a discount coupon on your account. Only one discount coupon can be redeemed.",
          type: "danger",
          duration: 0,
          id: "EligibilityError1",
        });

        dispatch(
          discountAppliedFailure({
            discountAppliedError: null,
          })
        );
        navigate("/checkout", {
          state: { ...wowDataObject, discountApplied: false },
        });
      }
    }
  }, [discountAppliedError]);
  const calculateCost = (data: any) => {
    const SELECTED_LINE: any = selectedLine;
    const planCost1: any =
      ((100 - data.discountPctg) * data.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data.additionalLinePrice;
    const planPerLinesCost1: any = Math.ceil(planCost1 / SELECTED_LINE);
    return {
      planCost1,
      planPerLinesCost1,
    };
  };

  const setSharableCost = (data: any, index: any) => {
    const { planCost1, planPerLinesCost1 } = calculateCost(data);
    let activeData =
      index != null ? props.fiveGPlans.data[index].planData : activePlan;
    setPlanCost(planCost1);
    setPlanPerLinesCost(planPerLinesCost1);
    setSharablePlans(data);
    setActivePlan(activeData);
  };

  const calculateUnlimitedCost = (item: any) => {
    const SELECTED_LINE: any = selectedLine;
    let planCost = 0;
    let planPerLinesCost = 0;
    let data = item[0];
    if (data.addLineSplitPrice) {
      let linePrice1 = 0;
      for (var i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data.discountPctg) * data.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data.discountPctg) * data.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data.additionalLinePrice;

      planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };

  const setUnlimitedCost = (data: any) => {
    const { planCost, planPerLinesCost } = calculateUnlimitedCost(data);
    setPlanUnlimitedCost(planCost);
    setPlanUnlimitedPerLinesCost(planPerLinesCost);
  };

  const handleOnPlanSelect = (data: any) => {
    setSelectedPlan(data);
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLine));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: selectedLine,
        plan: data,
      })
    );
    localStorage.setItem("isWowDiscountEligible", "true");

    if (data.isUnlimited) {
      localStorage.setItem(
        "reachCoupon",
        wowDiscountCoupons?.unlimited?.coupon
      );
    } else {
      localStorage.setItem("reachCoupon", wowDiscountCoupons?.regular?.coupon);
    }
    navigate("/checkout", {
      state: { ...wowDataObject, discountApplied: true },
    });
  };

  const lineTabChangeHandler = (val: number) => {
    setMultiplier(val);
    const line1Price = _.map(props.fiveGPlans.data, "additionalLinePrice");
    setPerLineCost(
      line1Price.map(function (x) {
        return x * val;
      })
    );
    let shareable = shareablePlans;
    let unlimited = unlimitedPlans;
    setTabVal(val);
    setSelectedLine(val);
    if (shareable || unlimited) {
      setSharableCost(shareable, null);
      setUnlimitedCost(unlimited);
    }
  };
  const proceedToCheckoutHandler = (data: string, wowObject?: any) => {
    if (data === "success") {
      setIsCheckEligibilityClicked(true);
      setWowDataObject(wowObject);
      let data = {
        wowCustomerId: wowObject.ecid,
      };
      dispatch(HomeService.discountAppliedApi(data));
      pushTrackingEventsToGAAndFB({
        category: "EligibilityCheckPopUp",
        actionType: "CheckEligibilitySuccess",
      });
    } else if (data === "skip") {
      localStorage.setItem("isWowDiscountEligible", "false");
      localStorage.removeItem("wowUserDetails");
      localStorage.setItem(
        "reachSelectedPlanLine",
        JSON.stringify(selectedLine)
      );
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          line: selectedLine,
          plan: selectedPlan,
        })
      );
      pushTrackingEventsToGAAndFB({
        category: "EligibilityCheckPopUp",
        actionType: "SkipEligibilityCheck",
      });
    } else if (data === "206-status") {
      setIsCheckEligibilityClicked(true);
      setWowDataObject(wowObject);
      let data = {
        wowCustomerId: wowObject.ecid,
      };
      dispatch(HomeService.discountAppliedApi(data));
      pushTrackingEventsToGAAndFB({
        category: "EligibilityCheckPopUp",
        actionType: "CheckEligibilityFailedContinue",
      });
    }
  };
  let isAuthenticatedUser = true;
  if (currentuser?.isAnonymous) {
    isAuthenticatedUser = false;
  } else if (currentuser?.isAnonymous === undefined) {
    isAuthenticatedUser = false;
  }
  useEffect(() => {
    if (!isAuthenticatedUser) {
      setWowCustomerData({
        ...initialWowCustomerData,
      });
    }
  }, [isAuthenticatedUser]);
  const validateData = (formData: any) => {
    if (
      formData.wowAccountNumber === "" ||
      formData.wowAccountServiceZipcode === "" ||
      formData.wowCustomerLastName === ""
      // formData.wowCustomerEmail === ""
    ) {
      return false;
    }
    if (
      formData.wowAccountNumber !== "" &&
      formData.wowAccountNumber.length !== 4
      // &&
      // !/^\d+$/.test(formData.wowAccountNumber)
    ) {
      return false;
    }
    if (
      formData.wowAccountServiceZipcode !== "" &&
      formData.wowAccountServiceZipcode.length !== 5
      // &&
      // !/^\d+$/.test(formData.wowAccountServiceZipcode)
    ) {
      return false;
    }

    // if (!validateEmail(formData.wowCustomerEmail)) {
    //   return false;
    // }

    return true;
  };
  const handleSubmit = (e: any) => {
    e.preventDefault();

    setformDataValidation(true);

    if (validateData(wowCustomerData)) {
      setIsCheckEligibilityClicked(true);

      let data = {
        // emailId: wowCustomerData.wowCustomerEmail,
        zipCode: wowCustomerData.wowAccountServiceZipcode,
        accountNum: wowCustomerData.wowAccountNumber,
        lastName: wowCustomerData.wowCustomerLastName.trim(),
        isUnlimited: selectedPlan.isUnlimited,
      };
      dispatch(HomeService.wowEligibilityApi({ data }));
      setformDataValidation(false);
      pushTrackingEventsToGAAndFB({
        category: "EligibilityCheckPopUp",
        actionType: "CheckEligibility",
      });
    }
  };
  const handleInputChange = (e: any) => {
    setWowCustomerData((prevProps) => ({
      ...prevProps,
      [e.target.name]: e.target.value,
    }));
  };
  const handleModalMenu = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
    pushTrackingEventsToGAAndFB({
      category: "EligibilityCheckPopUp",
      actionType: "CheckEligibilityAccountNumberHelp",
    });
  };
  const handleCloseModelMenu = () => {
    setAnchorEl(null);
  };

  const handleChange = async () => {
    await setChecked((prevChecked) => !prevChecked);
  };
  return (
    <div className="">
      {props.isAllowed && (
        <>
          <LineTab
            value={tabVal}
            handleChange={lineTabChangeHandler}
            totalCount={globalVal && globalVal.max_line_count}
          />
        </>
      )}
      <div
        className="cardsDiv"
        style={{
          display: "flex",
          paddingLeft: "0",
          // flexDirection: "row",
          justifyContent: "space-around",
          paddingBottom: "95px",
          borderBottom: "1px solid var(--dusty-orange)",
        }}
      >
        {wowDiscountModal && wowEligibilityCheckCounter <= 3 && (
          <WowDiscount
            isShowModal={wowDiscountModal}
            onModalClose={() => {
              setWowDiscountModal(false);
              if (isAuthenticatedUser) {
                setWowCustomerData((prevState) => ({
                  ...prevState,
                  wowAccountNumber: "",
                  wowAccountServiceZipcode: "",
                }));
              } else {
                setWowCustomerData({
                  ...initialWowCustomerData,
                });
              }
            }}
            handleSubmit={handleSubmit}
            wowCustomerData={wowCustomerData}
            handleInputChange={handleInputChange}
            formDataValidation={formDataValidation}
            handleModalMenu={handleModalMenu}
            open={open}
            popoverId={popoverId}
            anchorEl={anchorEl}
            handleCloseModelMenu={handleCloseModelMenu}
            isAuthenticatedUser={isAuthenticatedUser}
            isCheckEligibilityClicked={isCheckEligibilityClicked}
            proceedToCheckoutHandler={proceedToCheckoutHandler}
          />
        )}

        {wowDiscountSuccessModal && (
          <WowDiscountSuccess
            isShowModal={wowDiscountSuccessModal}
            onModalClose={() => setWowDiscountSuccessModal(false)}
            wowEligibility={wowEligibility}
            proceedToCheckoutHandler={proceedToCheckoutHandler}
            wowCustomerData={wowCustomerData}
            isCheckEligibilityClicked={isCheckEligibilityClicked}
          />
        )}

        {wowDiscountUnsuccessModal && (
          <WowDiscountUnsuccess
            isShowModal={wowDiscountUnsuccessModal}
            onModalClose={() => setWowDiscountUnsuccessModal(false)}
            wowEligibilityCheckCounter={wowEligibilityCheckCounter}
            tryAgainHandler={() => {
              setWowDiscountUnsuccessModal(false);
              if (isAuthenticatedUser) {
                setWowCustomerData((prevState) => ({
                  ...prevState,
                  wowAccountNumber: "",
                  wowAccountServiceZipcode: "",
                }));
              } else {
                setWowCustomerData({
                  ...initialWowCustomerData,
                });
              }
              setWowDiscountModal(true);
              pushTrackingEventsToGAAndFB({
                category: "EligibilityCheckPopUp",
                actionType: "CheckEligibilityFailedRetry",
              });
            }}
            continueHandler={() => {
              proceedToCheckoutHandler("skip");
              setWowDiscountUnsuccessModal(false);
              pushTrackingEventsToGAAndFB({
                category: "EligibilityCheckPopUp",
                actionType: "CheckEligibilityFailedContinue",
              });
            }}
            handleModalMenu={handleModalMenu}
            open={open}
            popoverId={popoverId}
            anchorEl={anchorEl}
            handleCloseModelMenu={handleCloseModelMenu}
            wowCustomerData={wowCustomerData}
          />
        )}

        {wowDiscountUnsuccessModalWithMatchFound && (
          <WowDiscountUnsuccessMatchFound
            isShowModal={wowDiscountUnsuccessModalWithMatchFound}
            onModalClose={() =>
              setWowDiscountUnsuccessModalWithMatchFound(false)
            }
            wowEligibility={wowEligibility}
            // continueHandler={() => {
            //   proceedToCheckoutHandler("206-status");
            //   setWowDiscountUnsuccessModalWithMatchFound(false);
            // }}
            continueHandler={proceedToCheckoutHandler}
            tryAgainHandler={() => {
              if (isAuthenticatedUser) {
                setWowCustomerData((prevState) => ({
                  ...prevState,
                  wowAccountNumber: "",
                  wowAccountServiceZipcode: "",
                }));
              } else {
                setWowCustomerData({
                  ...initialWowCustomerData,
                });
              }
              setWowDiscountUnsuccessModalWithMatchFound(false);
              setWowDiscountModal(true);
              pushTrackingEventsToGAAndFB({
                category: "EligibilityCheckPopUp",
                actionType: "CheckEligibilityFailedRetry",
              });
            }}
            wowCustomerData={wowCustomerData}
            isCheckEligibilityClicked={isCheckEligibilityClicked}
          />
        )}
        {planData &&
          wowNormalPlanResponse &&
          planData.data?.map((data: any, index: any) => {
            return (
              <PlanCard
                planDataObj={data}
                key={index}
                onPlanSelect={(e: any) => {
                  // if (wowEligibilityCheckCounter === 3) {
                  //   setWowDiscountUnsuccessModal(true);
                  // } else {
                  handleOnPlanSelect(e);
                  //   setWowDiscountModal(true);
                  // }
                }}
                unlimitedPlanDiscount={wowUnlimitedPlanResponse}
                normalPlanDiscount={wowNormalPlanResponse}
                wowEligibilityCheckCounter={wowEligibilityCheckCounter}
                planCost={planCost}
                planPerLinesCost={planPerLinesCost}
                noOfLinesCost={perLineCost}
                multiply={tabVal}
              />
            );
          })}
        <div
          className="showHideLabel my-4"
          style={{ width: "100%", textAlign: "center" }}
        >
          Show/Hide Broadband Labels
          <Switch
            checked={checked}
            onChange={handleChange}
            name="checkedB"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
        </div>
        {checked && (
          <>
            {planData &&
              wowNormalPlanResponse &&
              planData.data?.map((data: any, index: any) => {
                return (
                  <NutritionCard
                    planDataObj={data}
                    key={index}
                    onPlanSelect={(e: any) => {
                      handleOnPlanSelect(e);
                    }}
                    unlimitedPlanDiscount={wowUnlimitedPlanResponse}
                    normalPlanDiscount={wowNormalPlanResponse}
                    wowEligibilityCheckCounter={wowEligibilityCheckCounter}
                    planCost={planCost}
                    planPerLinesCost={planPerLinesCost}
                    noOfLinesCost={perLineCost}
                    multiply={tabVal}
                  />
                );
              })}
          </>
        )}
      </div>
    </div>
  );
};

export default LineSelect;
