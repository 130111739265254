import React, { useEffect } from "react";
import { Card, CardActions, CardContent, Typography } from "@mui/material";
import Button from "../../components/Button/Button";
import "./HomeSpotlightCards.scss";
import Fill from "../../assets/images/BillCalculator/fill-1-copy.svg";
import Fill1 from "../../assets/images/BillCalculator/fill-2-copy.svg";
import "../HomeComponents/BillPlanCard.scss";
import { globalVal } from "../../global/globalvalues";
import { pushTrackingEventsToGAAndFB } from "../../global/commonFunctions/GaAndFb";

type Props = {
  planDataObj?: any;
  onPlanSelect: (e: any) => void;
  unlimitedPlanDiscount?: any;
  normalPlanDiscount?: any;
  wowEligibilityCheckCounter?: number;
  planCost?: any;
  planPerLinesCost?: any;
  noOfLinesCost?: any;
  multiply?: any;
};

const NutritionCard: React.FC<Props> = (props) => {
  const planSelectHandler = (data: any) => {
    props.onPlanSelect(data);
    pushTrackingEventsToGAAndFB({
      category: "Plans",
      actionType: "Selected Plan",
      label: `${data?.name} 1`,
    });
  };

  let planCost, planPerLinesCost: any;

  if (props.planDataObj.isUnlimited) {
    if (props.planDataObj.addLineSplitPrice) {
      let linePrice1 = 0;
      for (var i = 1; i <= props.multiply - 1; i++) {
        linePrice1 = linePrice1 + props.planDataObj.addLineSplitPrice[i];
      }
      linePrice1 =
        linePrice1 -
        props.unlimitedPlanDiscount.discountInDollar -
        props.unlimitedPlanDiscount.secondaryDiscountInDollar *
          (props.multiply - 1);
      planCost =
        ((100 - props.planDataObj.discountPctg) *
          props.planDataObj.baseLinePrice) /
          100 +
        linePrice1;
      planPerLinesCost = planCost / props.multiply;
    }
  } else {
    if (props.normalPlanDiscount) {
      planCost =
        ((100 - props.planDataObj.discountPctg) *
          (props.planDataObj.baseLinePrice -
            props.normalPlanDiscount?.discountInDollar)) /
          100 +
        (props.multiply - 1) *
          (props.planDataObj.additionalLinePrice -
            props.normalPlanDiscount?.secondaryDiscountInDollar);
      planPerLinesCost = Math.ceil(planCost / props.multiply);
    }
  }

  return (
    <>
      <Card
        style={{
          width: "283px",

          // marginLeft: "0px",
          // marginRight: "0px",
          margin: "20px 23px",
        }}
        sx={{
          minWidth: 295,
          marginX: 4,
          maxWidth: 295,
          padding: 2,
        }}
      >
        <div className="broadband-card py-0 pt-2 w-100 text-left">
          <div className="fs-24 font-bold">Broadband Facts</div>
          <hr className="my-1 border-bottom-black-1"></hr>
          <div className="font-family-bold  fs-14 text-dark">
            {globalVal && globalVal.long_name}
          </div>
          <div className="fs-18 font-family-bold text-dark letter-sp-1 my-2">
            {props.planDataObj.name}
          </div>
          <div className="fs-12 text-dark font-family-semibold">
            Mobile Broadband Consumer Disclosure
          </div>
          <hr className="my-1 border-bottom-black-1"></hr>
          <div className="fs-16 font-family-semibold d-flex justify-content-between text-dark my-2">
            <div>Monthly Price</div>
            <div>${Math.ceil(planPerLinesCost).toFixed(2)}</div>
          </div>
          <hr className="my-1 border-bottom-black-1"></hr>
          <div className="fs-12 text-dark my-2 font-family-semibold">
            This monthly price ${Math.ceil(planPerLinesCost).toFixed(2)} is not
            an introductory rate. This monthly price does not require a
            contract.
          </div>
          <hr className="my-1 border-bottom-black-1"></hr>
          <span className="fs-12 font-family-bold text-dark">
            Additional Charges &amp; Terms
          </span>
          <div className="fs-12 font-family-bold pl-2 text-dark">
            Provider Monthly Fees{" "}
          </div>
          <div className="fs-12 d-flex justify-content-between pl-2 text-dark my-1 ml-1">
            <div className="text-nowrap font-family-medium">
              Cost Recovery Fee
            </div>
            <div className="text-nowrap">6% of total invoice</div>
          </div>
          <div className="fs-12 font-family-bold pl-2 text-dark">
            One-time Fees at the Time of Purchase
          </div>
          <div className="my-1 ml-1">
            <div className="fs-12 d-flex justify-content-between pl-2 text-dark">
              <div className="font-family-medium">Activation charges</div>
              <div className="font-family-medium">N/A</div>
            </div>
            <div className="fs-12 d-flex justify-content-between align-items-center pl-2 text-dark font-family-semibold ">
              <div className="my-1">SIM Card Shipping Fee</div>
              <div>$3.00 - $25.00</div>
            </div>
          </div>
          <div className="fs-12 font-family-medium mt-2">
            Features, such as data top-ups, international long distance calling,
            and roaming, are available at additional charge. Please visit{" "}
            <span
              className="text-cta font-family-semibold text-dark fs-12"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={() =>
                window.open(globalVal && globalVal.hostUrl, "_target")
              }
            >
              {globalVal && globalVal.hostUrl}
            </span>{" "}
            for details.
          </div>
          <div className="fs-12 d-flex justify-content-between mt-2 text-dark my-1">
            <div className="font-family-semibold">Early Termination Fee</div>
            <div className="fs-12 font-family-bold text-dark">N/A</div>
          </div>
          <div className="d-flex justify-content-between ">
            <div className="fs-12 text-dark font-family-semibold">
              Government Taxes
            </div>
            <div className="fs-12 text-dark font-family-semibold">
              Varies by location
            </div>
          </div>
          <hr className="mt-1 mb-2 mt-2 border-bottom-black-1"></hr>
          {/* <div className="my-2">
                                        <div className="flex-center-row-between">
                                            <div className="fs-12 font-family-bold letter-sp-1 text-dark">
                                                Discounts &amp; Bundles
                                            </div>
                                        </div>
                                        <div className="fs-12 px-2 my-2 text-dark">
                                            A discount of $10.00 is applicable on Nextlink Mobile plans exclusively for
                                            Nextlink Internet customers.
                                            <span
                                                className="text-cta font-family-regular text-dark fs-12"
                                                style={{ textDecoration: 'underline' }}
                                            >
                                                Click Here
                                            </span>{' '}
                                            for more information.
                                        </div>
                                    </div> */}
          <div className="fs-14 font-family-bold text-dark">
            Speeds Provided with Plan
          </div>
          {/* {props.planDataObj &&
            props.planDataObj.map((plan: any) => {
              return (
                <> */}
          {/* {plan.planData === activePlan && ( */}
          <>
            <div className="fs-12 font-family-semibold pl-2 text-dark my-1">
              {props.planDataObj?.labelInfo?.speedInfos?.map((info: any) => (
                <div>
                  <div className="fs-12 font-family-bold text-dark my-1">
                    {info?.band}
                  </div>
                  <div className="pl-1">
                    <div className="d-flex justify-content-between my-1">
                      <div className="fs-12 pl-2 text-dark font-family-semibold">
                        Typical Download Speed
                      </div>
                      <div className="fs-12 font-family-bold text-dark text-nowrap">
                        {info?.dlSpeed ?? "-"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between">
                      <div className="fs-12 pl-2 text-dark font-family-semibold">
                        Typical Upload Speed
                      </div>
                      <div className="fs-12 font-family-bold text-dark">
                        {" "}
                        {info?.ulSpeed ?? "-"}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between my-1">
                      <div className="fs-12 pl-2 text-dark font-family-semibold">
                        Typical Latency
                      </div>
                      <div className="fs-12 font-family-bold text-dark">
                        {info?.latency ?? "-"}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            <hr className="my-2 border-bottom-dark-1"></hr>
            <div className="fs-12 d-flex justify-content-between mt-2">
              <div className="text-dark fs-14 font-family-bold">
                Data Included with Monthly Price
              </div>
              <div className="text-dark font-family-bold">
                {props.planDataObj.planData}GB
              </div>
            </div>
            <div className="fs-12 text-dark font-family-semibold text-nowrap my-1">
              Charges for Additional Data Usage
            </div>
            <div className="fs-12 font-family-bold text-right">
              $5.00 for 0.5GB
            </div>
            <hr className="my-2 border-bottom-dark-1"></hr>
            <div className="fs-12 d-flex justify-content-between mt-1">
              <div className="font-family-bold text-dark text-nowrap">
                Network Management
              </div>
              <div className="cursor-pointer text-nowrap font-family-semibold text-dark">
                {" "}
                <a
                  onClick={() =>
                    window.open(
                      "https://4888946.fs1.hubspotusercontent-na1.net/hubfs/4888946/WowMobile_MVNO%20Website_Disclosure.pdf",
                      "_target"
                    )
                  }
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "#313131",
                    cursor: "pointer",
                  }}
                >
                  Read our Policy
                </a>
              </div>
            </div>
            <div className="fs-12 d-flex justify-content-between mt-1">
              <div className="font-family-bold text-dark">Privacy</div>
              <div className="cursor-pointer font-family-semibold text-dark">
                {" "}
                <a
                  onClick={() => window.open("/privacy-policy", "_target")}
                  target="_blank"
                  style={{
                    textDecoration: "underline",
                    color: "#313131",
                    cursor: "pointer",
                  }}
                >
                  Read our Policy
                </a>
              </div>
            </div>
            <hr className="my-2 border-bottom-dark-1"></hr>
            <div className="fs-14 mt-2 font-family-bold text-dark">
              Customer Support
            </div>
            <div className="fs-12 text-dark">
              <div className="my-1 font-family-semibold">Contact Us: </div>
              <div
                className="text-cta mr-1 text-dark"
                style={{ textDecoration: "underline" }}
              >
                {globalVal && globalVal.email}
              </div>
              <div className="my-1 font-family-semibold">
                {globalVal && globalVal.phoneNumber}
              </div>
            </div>
            <hr className="my-2 border-bottom-dark-1"></hr>
            <div className="fs-12 mt-2 text-dark font-family-medium">
              Learn more about the terms used on this label by visiting the
              Federal Communications Commission Consumer Resource Center.
            </div>
            <span
              className="text-cta fs-14 font-family-bold text-dark text-right w-100"
              style={{ display: "inline-block", cursor: "pointer" }}
              onClick={() =>
                window.open("https://www.fcc.gov/consumers", "_target")
              }
            >
              fcc.gov/consumer
            </span>
            <hr className="my-2 border-bottom-dark-1"></hr>
            <div className="fs-14 mt-2 text-dark pb-2 font-family-semibold">
              Unique Plan Identifier:
              <br></br>
              {props.planDataObj?.uniqueIdentifier}
            </div>
          </>
          {/* )} */}
          {/* </>
              );
            })} */}
        </div>
      </Card>
    </>
  );
};

export default NutritionCard;
